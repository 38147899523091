@tailwind base;
@tailwind components;
@tailwind utilities;

/**
* Rich Text Input
*/
.ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6, .ql-editor ol, .ql-editor p, .ql-editor ul {
  margin-bottom: 1rem!important;
}
