/* animate button on hover to bounce and change color gradually */
.animate-button {
  position: relative; /* Add position relative to create stacking context */
  overflow: hidden; /* Hide overflow to contain pseudo-element */

  &:hover {
    transition: all 1s ease;
    color: #000;
    transform: scale(1.1);
  }

  /* Pseudo-element for gradual color change background */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%; /* Start the gradient from the left edge */
    width: 100%;
    height: 100%;
    background-color: white;
    transition: left 1s ease; /* Transition the left position */
    z-index: -1;
  }

  /* Shift the pseudo-element on hover to reveal the new color */
  &:hover::before {
    left: 0;
  }

  /* reverse the animation when the cursor leaves the button */
  &:not(:hover) {
    transition: all 0.3s ease;
    //color: #fff;
    transform: scale(1);
  }

  /* Reset the svg transform on hover */
  svg {
    transform: translateX(0);
  }
}

/* fade in animation for the main content */
.fade-in {
  animation: fadeIn 2s ease;
  opacity: 1;
}

/* gradually bring hero section text in from the left */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

// fade in animation from the bottom
.fade-in-bottom {
  animation: fadeInBottom 1s ease;
  opacity: 1;
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// fade in animation from the right
.fade-in-right {
  animation: fadeInRight 1s ease;
  opacity: 1;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

//fade in animation from the top
.fade-in-top {
  animation: fadeInTop 1s ease;
  opacity: 1;
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-hover {
  &:hover {
    img {
      transform: scale(1.2);
      transition: transform 4s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }
  }
}

//change background color from the bottom up
.bg-animate {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1172b9;
    color: white;
    transition: top 0.5s ease;
    z-index: -1;
  }

  &:hover::before {
    top: 0;
  }
}
